import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import { Link, navigate } from 'gatsby'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import api from '../../api/credentials'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import { format } from 'date-fns'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const OrdersContent = ({ orders, pagination, setPage }) => {
    const { current_page, per_page, total } = pagination
    const [error, setError] = useState(null)

    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    })

    const refundOrder = (order) => {
        if (window.confirm('¿Seguro que quieres tramitar la devolución?')) {
            api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                api.post(`${process.env.API_URL}/order/refund?order=${order}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(data => {
                    navigate(`/refund?id=${order}`)
                }).catch(error => {
                    switch (error.response.status) {
                        case 401: // Not logged in
                        case 419: // Session expired

                            localStorage.removeItem('user')
                            window.dispatchEvent(new Event("removeUser"));
                            navigate('/signin')
                            break;

                        default:
                            setError(error.response.data.error)
                    }
                })
            })
        }
    }


    const handleClose = () => {
        setError(null)
    }

    const getStatus = (status) => {
        switch (status) {
            case 'completed':
                return (
                    <>
                        {/* <button onClick={() => refundOrder(order.id)} className='btn-refund-order w-100'>
                            Devolver
                        </button> */}
                       <span class='status bg-success text-white'>Completado</span>
                    </>
                )
            case 'refunded':
                return (
                   <span class='status  bg-warning text-dark'>Devuelto</span>
                )
            case 'failed':
                return (
                   <span class='status  bg-danger'>Error</span>
                )
            case 'pending':
                return (
                   <span class='status  bg-danger'>Pendiente</span>
                )
            case 'canceled':
                return (
                   <span class='status  bg-danger'>Cancelado</span>
                )
            default:
                return (
                   <span class='status  bg-dark'>Estado desconocido</span>
                )
        }
    }

    return (
        <section className='my-orders-desc ptb-50'>
            <div className='container'>
                {orders.length > 0 ?
                    <>
                        <div className='mb-5 row'>
                            <div className='col-12'>
                                <div class="orders">
                                    <ul>
                                        {orders.map((order) => (

                                            <>
                                                <li class="">
                                                    <a aria-current="page" class="d-flex justify-content-between align-items-center"
                                                        href={`/order-details?id=${order.id}`}>
                                                        <span class="courses-name"> Nº de pedido: {order.locator}</span>
                                                        <div class="courses-meta">
                                                            <span class="date">{format(new Date(order.created_at), 'dd/MM/yyyy HH:mm')}</span>
                                                            <span class="duration">Total: {formatter.format(order.total)}</span>
                                                            {getStatus(order.statuses[order.statuses.length - 1]?.status, order)}

                                                        </div>
                                                    </a>
                                                </li>
                                            </>





                                        ))}

                                    </ul>

                                </div>

                            </div>
                        </div>
                        <Pagination
                            activePage={current_page ? current_page : 0}
                            itemsCountPerPage={per_page ? per_page : 0}
                            totalItemsCount={total ? total : 0}
                            onChange={(pageNumber) => {
                                setPage(pageNumber)
                            }}
                            pageRangeDisplayed={4}
                            itemClass='page-item'
                            linkClass='page-link'
                        />
                    </>
                    :
                    <p>No tienes pedidos</p>
                }
            </div>
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </section>
    )
}

export default OrdersContent