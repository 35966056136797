import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import OrdersContent from '../components/Orders/OrdersContent'
import api from '../api/credentials'
import {  navigate } from 'gatsby'

const Orders = () => {
    const [orders, setOrders] = useState(null)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                setPagination(null)
                setOrders(null)
                api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                    api(`${process.env.API_URL}/orders?page=${page}`, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(data => {
                        setPagination(data.data)
                        setOrders(data.data.data)
                    }).catch(error => {
                        switch (error.response.status) {
                            case 401: // Not logged in
                            case 419: // Session expired

                                localStorage.removeItem('user')
                                window.dispatchEvent(new Event("removeUser"));
                                navigate('/signin')
                                break;
                           
                            default:
                                navigate('/404')
                        }
                    })
                })
            } catch (error) {
                // console.log(error)
                setPagination(null)
                setPage(1)
                setOrders(null)
                navigate('/404')
            }
        })()
    }, [page])

    if (!orders) return null

    return (
        <Layout title='Mis pedidos'>
            <PageBanner
                pageTitle='Mis Pedidos'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Mis Pedidos'
            />
            <OrdersContent
                orders={orders}
                pagination={pagination}
                setPage={setPage}
            />
        </Layout>
    )
}

export default Orders